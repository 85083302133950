.main-header {
    background-color: #f8f9fa;
    padding: 10px 20px;
  }
  
  .top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .service-name-container {
    flex: 1;
    margin-right: 20px;
  }
  
  .service-name-display {
    font-size: 16px;
    font-weight: bold;
  }
  
  .top-logo {
    flex: 0;
  }
  
  .top-header-logout {
    display: flex;
    justify-content: flex-end;
  }
  
  .logout-button {
    padding: 8px 16px;
    border: none;
    background-color: #ff6600;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #e65c00;
  }
  