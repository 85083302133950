/* Style for the form container */
.form-container {
    padding: 20px;
    max-width: 500px;
    margin: auto;
    text-align: center; /* Center the content */
  }
  
  /* Style for the heading */
  .form-container h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  /* Style for the form elements */
  .form-container label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
  }
  
  /* Style for the number input */
  .number-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    margin-bottom: 15px; /* Add spacing between form elements */
  }
  
  /* Style for the dropdown container */
  .dropdown {
    margin-bottom: 15px; /* Add spacing between form elements */
    position: relative;
  }
  
  /* Style for the dropdown icon */
  .icon-d {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #999;
  }
  
  /* Style for the select element */
  .dropdown select {
    width: 100%;
    padding: 10px 30px; /* Adjust padding to make room for the icon */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    appearance: none; /* Remove default styling for select */
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PHBhdGggZD0iTTEyLjUgM2MtMS44MyAwLTMuMTk3LDEuMzE5LTQuNTI3LDMuNzM1bDIuNTI1LTIuNTI2Yy44NTQtMS4zNjUsMS42NDEtMi40OTcsMi4yMjQtMy4yNDN6IiBmaWxsPSIjOTk5Ii8+PC9zdmc+');
    background-repeat: no-repeat;
    background-position: right 10px center; /* Align the icon to the right */
  }
  
  /* Style for the select element on focus */
  .dropdown select:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none; /* Remove the default outline */
  }
  
  /* Style for the submit button */
  .submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    margin-top: 20px; /* Add some space above the button */
  }
  
  /* Style for the submit button on hover */
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .data-table th {
    background-color: #f4f4f4;
  }
  
  .data-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .data-table tbody tr:hover {
    background-color: #e2e2e2;
  }
  
  /* General Form Container */
.form-container {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
  }
  
  /* Form Heading */
  .form-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Form Elements */
  .form-container label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
    color: #555;
  }
  
  .form-container input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-container input[type="number"]:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  /* Submit Button */
  .submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Error Messages */
  .toast-error {
    color: #dc3545;
    font-size: 14px;
    margin-top: 10px;
  }
  
  /* Download Button */
  .download-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .download-button:hover {
    background-color: #218838;
  }
  
  /* Pagination Controls */
  .pagination-controls {
    margin-top: 20px;
    text-align: center;
  }
  
  .pagination-controls button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin: 0 5px;
  }
  
  .pagination-controls button:hover {
    background-color: #0056b3;
  }
  
  .pagination-controls span {
    font-size: 14px;
    margin: 0 10px;
  }
  