/* UserDetails.css */
.tabcontent {
  float: left;
  padding: 0px 12px;
  width: calc(100% - 223px);
  border-left: none;
}

.subscribers-sec {
    padding: 20px;
  }
  
  .subscribers-home-l {
    font-size: 16px;
    color: #333;
  }
  
  .form-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  
  h1 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .form {
    margin-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
  
  .number-input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
  }
  
  .submit-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #dd4814;
    color: white;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #dd4814;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .data-table th {
    background-color: #f4f4f4;
  }
  
  .pagination-controls {
    margin-bottom: 20px;
    display: grid;
    place-content: center;
    grid-template-columns: 120px 120px;
  }
  
  .pagination-controls button {
    padding: 10px 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f4f4f4;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .pagination-controls button:disabled {
    background-color: #dd4814;
    cursor: not-allowed;
  }
  
  .download-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #dd4814;
    color: white;
    cursor: pointer;
  }
  
  .download-button:hover {
    background-color: #dd4814;
  }
  
  .loading-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #dd4814;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  